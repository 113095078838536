import { AnonymousCard } from './anonymous-card';
import { SmallAnonymousCard } from './small-anonymous-card';

let watermark = "NONAME";

export function getCardAutotabChain(
    context: JQuery,
    filter?: string
): AutotabChainItem[] {
    function filterQuery(query: JQuery) {
        if (!filter) return query;

        return query.filter(filter);
    }

    const anonymousCard = new AnonymousCard(context);
    const fields = anonymousCard.getContainer(false);
    const chain: AutotabChainItem[] = [
        {
            src: filterQuery(fields.Pan),
            predicate: CommonMisc.PanAutotabPredicate,
            moveNextCondition: CommonMisc.PanAutotabPredicate,
            moveOnKeyPress: SmallAnonymousCard.canMoveOnKeypress,
            maxLen: 23,
        },
    ];

    if (!fields.ExpiryMonthYear.length) {
        // TODO: Remove case after old school widget are dead.
        chain.push(
            {
                src: fields.ExpiryMonth,
                predicate: (src) => src.val()?.length >= 2,
            },
            {
                src: fields.ExpiryYear,
                predicate: (src) => src.val()?.length >= 2 && src.valid(),
            }
        );
    } else {
        chain.push({
            src: filterQuery(fields.ExpiryMonthYear),
            predicate: (src) => src.val()?.length >= 4 && src.valid(),
            maxLen: 5,
        });
    }

    chain.push({
        src: filterQuery(fields.Cvc),
        predicate: (src) => src.val()?.length >= 3 && src.valid(),
        moveOnKeyPress: true,
        maxLen: 3,
    });

    return chain;
}

export function filterInputs(context: JQuery) {
    return $(
        `input:visible, input[type='hidden'], select, .vncHiddenPan, .vncHiddenCvc, [name=Sum]`,
        context
    ).filter(
        (_, element) =>
            !$(element)
                .closest(SmallAnonymousCard.contextSelector)?.is(":hidden") && !$(element).closest(".vncSelectItem").length
    );
}

export function Init() {
    $(".b-card-block, .vncAnonymousCardBig").each((i, cardBlock) => {
        const $context = $(cardBlock);
        const $form = $context.closest("form");
        const anonymousCard = new AnonymousCard($context);

        $form.on("bingingchanged", ClearCVV);
        $form.on("postaction", $.proxy(_onPostAction, $context));
        $form.on("beforeaction", $.proxy(_onBeforeAction, $context));
        $context.off("card-detected").on("card-detected", OnCardDetected);

        const cardHolder = $context.find("[name='CardholderName']");
        cardHolder.inputmask("Regex", { regex: "[a-zA-Z- ]*" });

        const fields = anonymousCard.getContainer(false);
        fields.Cvc.inputmask("card-cvc");
        fields.Pan.val(fields.Pan.val().replace(/\D/g, ''))
        fields.Pan.inputmask("card-pan");
        fields.ExpiryMonthYear?.inputmask("card-expiry");
        fields.ExpiryMonth.inputmask("Regex", { regex: "^[0-9]{2}$" });
        fields.ExpiryYear.inputmask("Regex", { regex: "^[0-9]{2}$" });

        (fields.ExpiryMonthYear.length
            ? SmallAnonymousCard
            : $
        ).createAutotabChain(getCardAutotabChain($context));

        cardHolder.val(watermark);
        cardHolder.blur(function () {
            if ($(this).val().length == 0) {
                $(this).val(watermark);
            }
        });

        cardHolder.focus(function () {
            if ($(this).val() == watermark) {
                $(this).val("");
            }
        });

        $context.find("[name='CardholderName']").translit({ type: "iso" });

        if (anonymousCard.getField("Pan", true).val()) {
            setTimeout(() => {
                //запустить подсветку и форматирование номера
                fields.Pan.change();
                fields.Pan.keyup();
                anonymousCard.getActiveExpiry().first().focus();
            }, 1000);
        }

        fields.Pan.bind("paste", function () {
            setTimeout(
                $.proxy(() => {
                    $(this).keyup();
                }, this),
                0.3e3
            );
        });

        fields.Pan.bind("cut", function () {
            setTimeout(
                $.proxy(() => {
                    $(this).keyup();
                }, this),
                0.3e3
            );
        });
    });
    //Changes for new pay. Need for checkbox in container with tabs
    $(document).on("change", "[name='AddToMasterPass']", function () {
        (this.checked
            ? UI.Visibility.mShow
            : UI.Visibility
                .mHide)(CommonMisc.GetVisibilityKey($(".b-tabs__tab.is-show"), "offer-masterpass"));

        //Update commission for transfers masterpass Action
        if (!!window.Transfers)
            window.Transfers.UpdateCommisionForMasterPassAction(this);
    });

    bindValuesChange();
}

function _onBeforeAction() {
    var cardHolder = $(this).find("[name='CardholderName']");
    if (cardHolder.val() == watermark) {
        cardHolder.val("");
    } else if (/[а-яА-ЯЁё]/.test(cardHolder.val())) {
        cardHolder.translitIt();
    }
}
function _onPostAction() {
    ClearCard();
    ClearCVV();
}

export function ClearCard() {
    var $form = $("form:visible");
    $("[name='Pan']", $form).val("");
    $("[name='ExpiryMonth']", $form).val("");
    $("[name='ExpiryYear']", $form).val("");
    $("[name='ExpiryMonthYear']", $form).val("");
    $("[name='CardholderName']", $form).val("");
    $("[name='Cvc']", $form).val("");
    $(
        ".b-card-block__field, [data-valid-class_holder='true']",
        $form
    ).removeClass("is-success");
    $(".b-card-block__bank-list .b-card-block__bank").removeClass("is-active");
    $(".b-input__prefix_bank")
        .find(".b-input__bank")
        .removeClass("b-input__bank_visa")
        .removeClass("b-input__bank_mastercard")
        .removeClass("b-input__bank_mir");
}

export function ClearCVV() {
    $("[name='Cvc']").val("");
}

function mapCardTypeToIcon(type: string) {
    return type?.indexOf('maestro') == 0
        ? 'mastercard'
        : type;
}

export function OnCardDetected(e: Event, type: string = '') {
    const feature_SITE_736 = new CommonMisc.FeatureToggle().isEnabled('SITE_736');
    var parentWrapper = $(e.target).closest(".b-card-block");

    if (parentWrapper.length == 0)
        parentWrapper = $(e.target).closest(".b-payment-card");

    if (parentWrapper.length) {
        const iconType = feature_SITE_736? type?.toLowerCase() || type : mapCardTypeToIcon(type);
        //Bank card component
        if (feature_SITE_736) {
            $("b-payment-card__bank").hide();
            $(".b-card-block__bank-list .b-card-block__bank").hide();
        }        
        $(".b-card-block__bank-list .b-card-block__bank.is-active")
            .removeClass("is-active");

        $(".b-card-block__bank-list .b-card-block__bank_" + iconType)
            .addClass("is-active");
        $(".b-card-block__bank-list .b-card-block__bank.is-active").show();

        var $panPrefix = $("[name='Pan']", parentWrapper)
            .siblings(".b-input__prefix_bank")
            .find(".b-input__bank");
        $panPrefix
            .removeClass("b-input__bank_visa")
            .removeClass("b-input__bank_mastercard")
            .removeClass("b-input__bank_mir")
            .removeClass("b-input__bank_maestro")
            .removeClass("b-input__bank_unionpay");
        $panPrefix.addClass("b-input__bank_" + iconType);

        //new big card component
        if (feature_SITE_736) {
            $(".b-payment-card__bank-list .b-payment-card__bank.is-active").hide();
        }
        $(".b-payment-card__bank-list .b-payment-card__bank.is-active")
            .removeClass("is-active");
        
        $(".b-payment-card__bank-list .b-payment-card__bank_" + iconType)
            .addClass("is-active");
        $(".b-payment-card__bank-list .b-payment-card__bank.is-active").show();
    }

    if (type && type.length >= 0) {
        var bin = (<string>$(e.target).val().replace(" ", "")).substr(0, 8);
        //check masterpass
        if (
            bin.length > 5 &&
            bin.length < 9 &&
            CommonMisc.Cards.IsMasterpassBindable(bin)
        ) {
            $(".vcnBindCardToMasterpass", parentWrapper).show();
            $(".addCardBlock .vcnBindCardToMasterpass").show();
            if (
                $(".vncAddAnonymousToMasterpass[name='AddToMasterPass']:visible").is(
                    ":checked"
                )
            )
                UI.Visibility.mShow(
                    CommonMisc.GetVisibilityKey(
                        $(".b-tabs__tab.is-show"),
                        "offer-masterpass"
                    )
                );
        } else {
            $(".vcnBindCardToMasterpass", parentWrapper).hide();
            $(".addCardBlock .vcnBindCardToMasterpass").hide();
            UI.Visibility.mHide(
                CommonMisc.GetVisibilityKey(
                    $(".b-tabs__tab.is-show"),
                    "offer-masterpass"
                )
            );
        }
        if (!window.isWidget) {
            if (
                bin.length > 5 &&
                bin.length < 9 &&
                !CommonMisc.IsBankAccoutLinked()
            ) {
                CommonMisc.Cards.CheckMtsBankBin(bin, OnMtsBinChecked);
            } else {
                OnMtsBinChecked({ isMTSBin: false });
            }
        }
    } else {
        $(".vcnBindCardToMasterpass", parentWrapper).show();
        $(".addCardBlock .vcnBindCardToMasterpass").show();
        if (
            $(".vncAddAnonymousToMasterpass[name='AddToMasterPass']:visible").is(
                ":checked"
            )
        ) {
            UI.Visibility.mShow(
                CommonMisc.GetVisibilityKey(
                    $(".b-tabs__tab.is-show"),
                    "offer-masterpass"
                )
            );
        }
        OnMtsBinChecked({ isMTSBin: false });
    }
}

function OnMtsBinChecked(result: any) {
    $("form:visible").trigger("mtsbinchecked", result.isMTSBin);
}

export function GetActivePanInput(context: JQuery): JQuery {
    const res = $(
        `${SmallAnonymousCard.contextSelector}:visible .vncHiddenPan`,
        context
    );
    if (res.length) return res;

    const pan = new AnonymousCard(context).getField("Pan");
    return pan;
}

export function getExpFieldObserver(field: JQuery) {
    //проверяем что бы инпутмаск не убрал maxlength для срока действия карты
    var expFieldObserver = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
            if (mutation.type == "attributes" && mutation.attributeName.toLowerCase() === "maxlength") {
                field.each((i, el) => {
                    const maxlength = $(el).attr("maxlength");
                    if (!maxlength) {
                        $(el).attr("maxlength", "5");
                    }
                })
            }
        });
    });

    field.each((i, ccexp) => {
        expFieldObserver.observe(ccexp, {
            attributes: true
        });
    });
    return expFieldObserver;
}

export function FocusPanInput(context: JQuery): void {
    new AnonymousCard(context).getField("Pan").focus();
}

function bindValuesChange() {
    if (!(window as any).GTM) return;

    const ctx = $(".b-card-block, .vncAnonymousCard");

    const fields = [
        { field: $("[name=Pan]", ctx), eventLabel: "nomer_karty" },
        { field: $("[name=CardholderName]", ctx), eventLabel: "imya_vladelca" },
        { field: $("[name=Cvc]", ctx), eventLabel: "cvv" },
        { field: $(".vncCvcInput", ctx), eventLabel: "cvv" },
        {
            field: $("[name=ExpiryMonthYear]", ctx),
            eventLabel: "srok_deistviya_karty",
        },
        {
            field: $("[name=ExpiryMonth]", ctx),
            eventLabel: "srok_deistviya_karty",
        },
        {
            field: $("[name=ExpiryYear]", ctx),
            eventLabel: "srok_deistviya_karty",
        },
    ];

    fields.forEach((item) =>
        (window as any).GTM.BindValueChange(
            item.field,
            "novaya_karta",
            item.eventLabel,
            null,
            null,
            null
        )
    );
}

export { Expiry } from './expiry';
export { ProcessingErrorMapper } from './processing-error-mapper';
export { AutotabChain } from './autotab-chain';
export {
    AnonymousCard,
    SmallAnonymousCard
}
