export class Expiry implements IExpiry {
    constructor(public Month: number, public Year: number) { }

    public static parse(expiredDateValue: string): IExpiry {
        if (!expiredDateValue || expiredDateValue.length != 4)
            return new Expiry(null, null);

        let month = parseInt(expiredDateValue.substring(0, 2));
        let year = parseInt(expiredDateValue.substring(2));

        return new Expiry(month, year);
    }
}

export interface IExpiry {
    Month: number;
    Year: number;
}
