import { DoubleLinkedList, DoubleLinkedNode } from "./double-linked-list";
import { Key } from "./enums";

export class AutotabChain {
    private readonly _chainHolder: DoubleLinkedList<AutotabChainItem> = new DoubleLinkedList<AutotabChainItem>();

    constructor(chain: AutotabChainItem[]) {
        chain?.forEach((item) => this.insert(item), this);
    }

    public find(item: AutotabChainItem): DoubleLinkedNode<AutotabChainItem> {
        return this.eachUntill((node) => item === node.content);
    }

    private eachUntill(
        checkNode: (node: DoubleLinkedNode<AutotabChainItem>) => boolean
    ): DoubleLinkedNode<AutotabChainItem> {
        const iterator = this._chainHolder.getIterator();
        let current = iterator.next();
        while (!current.done) {
            if (checkNode(current.value)) {
                return current.value;
            }

            current = iterator.next();
        }

        return null;
    }

    public remove(item: AutotabChainItem): this {
        if (!item) throw new Error(`Argument is empty.`);

        this._chainHolder.remove(item);
        this.unsubscribe(item, "focus keydown keyup");

        return this;
    }

    public append(...items: AutotabChainItem[]): this {
        if (!items?.length) return;

        items.forEach((item) => this.insert(item), this);

        return this;
    }

    public prepend(...items: AutotabChainItem[]): this {
        if (!items?.length) return;

        const first = this._chainHolder.getIterator().next();
        items.forEach((item) => this.insert(item, first.value.content));

        return this;
    }

    // TODO: Create class and use copying constructor instead of item mutation.
    private filterQuery(item: AutotabChainItem): AutotabChainItem {
        this.eachUntill((node) => {
            if (!item.selector) {
                item.src = this.getQuery(item).not(node.content.src);
            }
            return false;
        });

        return item;
    }

    public insert(item: AutotabChainItem, beforeItem?: AutotabChainItem): this {
        this.filterQuery(item);

        const currentNode = this._chainHolder.insert(item, beforeItem);

        try {
            this.subscribe(currentNode.content, "focus", (event) => {
                setTimeout(
                    () =>
                        !CommonMisc.filterFocusable(this.getQuery(currentNode.content))
                            .length && this.next(currentNode)
                );
            });

            this.subscribe(currentNode.content, "keydown", (e) => {
                const element = CommonMisc.filterFocusable(
                    this.getQuery(currentNode.content)
                );

                if (!element.length) return;

                const len = element.val().length;
                const isEmpty = !len;
                let selectionOnStart;
                let selectionOnEnd;
                const sourceElement = element[0];


                if (CommonMisc.isInput(sourceElement)) {
                    const inputSourceElement = sourceElement as HTMLInputElement;
                    selectionOnStart = inputSourceElement.selectionStart === 0;
                    selectionOnEnd =
                        inputSourceElement.selectionEnd &&
                        inputSourceElement.selectionEnd === currentNode.content.maxLen;
                }

                if (
                    e.keyCode == Key.TAB &&
                    currentNode.content.predicate &&
                    !currentNode.content.predicate(element)
                ) {
                    e.preventDefault();
                } else if (
                    (e.keyCode == Key.LEFT_ARROW) &&
                    (isEmpty || selectionOnStart)
                ) {
                    e.preventDefault();
                    this.prev(currentNode);
                } else if (
                    (e.keyCode == Key.RIGHT_ARROW || e.keyCode == Key.ENTER) &&
                    currentNode.content.predicate &&
                    currentNode.content.predicate(element) &&
                    (selectionOnEnd ||
                        (currentNode.content.moveNextCondition &&
                            currentNode.content.moveNextCondition(element)))
                ) {
                    e.preventDefault();
                    this.next(currentNode);
                }
            });

            this.subscribe(
                currentNode.content,
                "input",
                CommonMisc.withDelay((e) => {
                    if (e.type === "input" && !((e.originalEvent as InputEvent)?.data)) {
                        return;
                    }
                    this.handleEvent(this.normalizeEvent(e), currentNode);
                })
            );

            this.subscribe(
                currentNode.content,
                "paste keyup",
                CommonMisc.withDelay((e) => {
                    if (e.type === "keyup" && e.keyCode === 229) {
                        return;
                    }
                    this.handleEvent(this.normalizeEvent(e), currentNode);

                })
            );
        } catch (error) {
            currentNode.content && this.remove(currentNode.content);
            throw error;
        }

        return this;
    }

    private getQuery(item: AutotabChainItem) {
        return item.selector ? $(item.selector, item.src) : item.src;
    }

    private unsubscribe(item: AutotabChainItem, events: string): JQuery {
        const namespacedEvents = events
            .split(/[ ,]/)
            .map(this.addNamespace)
            .join(" ");

        return item.src.off(namespacedEvents, item.selector);
    }

    private subscribe(
        item: AutotabChainItem,
        events: string,
        handler: (eventObject: JQueryEventObject, ...args: any[]) => any
    ): JQuery {
        const namspacedEvents = events
            .split(/[ ,]/)
            .map(this.addNamespace)
            .join(" ");

        return this.unsubscribe(item, events).on(
            namspacedEvents,
            item.selector,
            handler
        );
    }

    private addNamespace(event: string): string {
        return `${event}.autotab`;
    }

    private next(current: DoubleLinkedNode<AutotabChainItem>): void {
        this.innerNext(current) && this.getQuery(current.content).blur();
    }

    private prev(current: DoubleLinkedNode<AutotabChainItem>): void {
        this.innerPrev(current) && this.getQuery(current.content).blur();
    }

    private innerPrev(current: DoubleLinkedNode<AutotabChainItem>): boolean {
        if (current.prev?.content) {
            const previousItem = current.prev.content;
            const previousVisibleQuery = CommonMisc.filterFocusable(
                this.getQuery(previousItem)
            );

            if (!previousVisibleQuery.length) {
                return this.innerPrev(current.prev);
            }

            if (previousItem.prevAction) {
                previousItem.prevAction();
                CommonMisc.setCursorToLastSymbol(previousVisibleQuery);
            } else {
                previousVisibleQuery.first().animate({}, "slow", function () {
                    const previousElement = $(this);
                    previousElement.first().focus();
                    CommonMisc.setCursorToLastSymbol(previousElement);
                });
            }

            return true;
        }
    }

    private innerNext(current: DoubleLinkedNode<AutotabChainItem>): boolean {
        if (current.next?.content) {
            const nextItem = current.next.content;
            const nextVisibleQuery = CommonMisc.filterFocusable(
                this.getQuery(nextItem)
            );

            if (!nextVisibleQuery.length) {
                return this.innerNext(current.next);
            }

            nextItem.onBeforeNextFocus &&
                nextItem.onBeforeNextFocus(nextVisibleQuery);
            nextVisibleQuery.first().animate({}, "slow", function () {
                const nextElement = $(this);
                nextElement.focus();
                CommonMisc.setCursorToLastSymbol(nextElement);
            });

            return true;
        }
    }

    private handleEvent(e: ICompatibleInputEvent, currentNode: DoubleLinkedNode<AutotabChainItem>) {
        const content = currentNode.content;
        const element = CommonMisc.filterFocusable(this.getQuery(content));

        if (!element.length) return;

        const len = element.val().length;
        const isEmpty = !len;
        const sourceElement = element[0];
        let selectionOnEnd;
        let selectionOnStart;
        const lastVal = element.data('lastValue') || '';
        const isChanged = element.val() !== lastVal;

        if (CommonMisc.isInput(sourceElement)) {
            const inputSourceElement = sourceElement as HTMLInputElement;
            const maxValueLength = parseInt(
                sourceElement.getAttribute("data-maxlength")
            );
            selectionOnStart = inputSourceElement.selectionStart === 0;
            selectionOnEnd =
                inputSourceElement.selectionEnd == content.maxLen ||
                inputSourceElement.value?.length >= maxValueLength;
        }

        if (
            (e.type === "paste" ||
                (e.keyCode >= 48 && e.keyCode <= 57) ||
                (e.keyCode >= 96 && e.keyCode <= 105) ||
                e.keyCode == Key.ENTER) &&
            (e.keyCode == Key.ENTER ||
                selectionOnEnd ||
                (CommonMisc.resolve(content.moveOnKeyPress, element) &&
                    content.moveNextCondition &&
                    content.moveNextCondition(element))) &&
            content.predicate &&
            content.predicate(element)
        ) {
            this.next(currentNode);
        } else if ((e.keyCode == Key.BACKSPACE) &&
            !isChanged &&
            (isEmpty || selectionOnStart)) {
            e.originalEvent.preventDefault();
            this.prev(currentNode);
        }
        element.data('lastValue', element.val());
    }

    private normalizeEvent(event: JQueryEventObject): ICompatibleInputEvent {
        const e: ICompatibleInputEvent = {
            type: event.type
        };

        if (event.type === "paste") {
            return e;
        }
        if (event.type === "input") {
            e.originalEvent = event.originalEvent as InputEvent;
            if (e.originalEvent.inputType === "deleteContentBackward") {
                e.keyCode = Key.BACKSPACE
            }
            e.keyCode = e.originalEvent.data.charCodeAt(0);
            return e;
        }
        if (event.type === "keyup") {
            e.originalEvent = event.originalEvent as KeyboardEvent;
            e.keyCode = event.keyCode;
            return e;
        }

    }
}

interface ICompatibleInputEvent {
    keyCode?: number;
    type?: string;
    originalEvent?: KeyboardEvent | InputEvent;
}

export { DoubleLinkedNode }
