export class DoubleLinkedList<Content> {
    private _length: number;
    private _head: DoubleLinkedNode<Content>;
    private _tail: DoubleLinkedNode<Content>;

    constructor(...elements: Content[]) {
        this._head = new DoubleLinkedNode<Content>(null, null, null);
        this._tail = new DoubleLinkedNode<Content>(null, this._head, null);
        this._head.next = this._tail;
        this._length = 0;

        elements?.forEach((element) => this.insert(element));
    }

    /**
     * Insert item in list. If beforeItem is missing inserts at the end of list.
     * @param item Item to insert.
     * @param beforeItem The item before which target item will be placed. If missing inserts at the end of list.
     * @returns Inserted node.
     */
    public insert(
        item: Content,
        beforeItem?: Content
    ): DoubleLinkedNode<Content> {
        if (!item) throw new Error("Item to insert is null.");

        const nextNode = beforeItem ? this.find(beforeItem) : this._tail;

        if (!nextNode) {
            console.error("Item not found", beforeItem);
            return null;
        }

        const node = new DoubleLinkedNode(item, nextNode.prev, nextNode);
        node.prev.next = node;
        node.next.prev = node;
        this._length++;

        return node;
    }

    public remove(item: Content): void {
        const nodeToRemove = this.find(item);
        if (!nodeToRemove) {
            console.error("Item not found", item);
            return;
        }

        nodeToRemove.prev.next = nodeToRemove.next;
        nodeToRemove.next.prev = nodeToRemove.prev;
        nodeToRemove.next = nodeToRemove.prev = null;
        this._length--;
    }

    public getIterator(): IteratorProtocol<DoubleLinkedNode<Content>> {
        const tail = this._tail;
        let current = this._head;

        return {
            next() {
                const done = current.next === tail;
                if (!done) {
                    current = current.next;
                }

                return {
                    done: done,
                    value: current,
                };
            },
        };
    }

    public getLength() {
        return this._length;
    }

    private find(item: Content): DoubleLinkedNode<Content> {
        const iterator = this.getIterator();
        let current = iterator.next();
        while (!current.done) {
            if (item === current.value.content) {
                return current.value;
            }

            current = iterator.next();
        }

        return null;
    }
}

export class DoubleLinkedNode<Content> {
    constructor(
        private readonly _content: Content,
        public prev: DoubleLinkedNode<Content>,
        public next: DoubleLinkedNode<Content>
    ) { }

    public get content(): Content {
        return this._content;
    }

    public getIndex(): number {
        let count = 0;
        for (
            let current: DoubleLinkedNode<Content> = this;
            !!current.prev;
            current = current.prev
        ) {
            count++;
        }
        return count;
    }
}

interface IteratorProtocol<Item> {
    next(): {
        done: boolean;
        value?: Item;
    };
}
