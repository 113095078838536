import { Expiry, IExpiry } from "./expiry";

export const smallCardContextSelector = ".vncAnonymousCardSmall";

export function createTuple<T extends string[]>(...args: T) {
    return args;
}

export class AnonymousCard {
    public static readonly fieldsInfo: AnonymousCardField[] = [
        {
            name: 'Pan',
            commissionDetails: {
                required: true
            }
        },
        {
            name: 'ExpiryMonth',
            legacy: true,
            commissionDetails: {
                required: true
            }
        },
        {
            name: 'ExpiryYear',
            legacy: true,
            commissionDetails: {
                required: true
            }
        },
        {
            name: 'ExpiryMonthYear',
            legacy: false,
            commissionDetails: {
                required: true
            }
        },
        {
            name: 'Cvc'
        }
    ];

    public static readonly fieldNames = createTuple(
        'Pan',
        'ExpiryMonth',
        'ExpiryYear',
        'ExpiryMonthYear',
        'Cvc'
    );

    constructor(private readonly _context?: JQuery) { }

    public getContainer(onlyActive: boolean): AnonymousCardFieldsContainer {
        let container = {} as AnonymousCardFieldsContainer;

        AnonymousCard.fieldNames.forEach(
            (name) => (container[name] = this.getField(name, onlyActive))
        );

        return container;
    }

    public getActiveExpiry(): JQuery {
        const anonymousCard = this.getContainer(true);

        return anonymousCard.ExpiryMonthYear.length
            ? anonymousCard.ExpiryMonthYear
            : anonymousCard.ExpiryMonth;
    }

    // TODO: Move isLegacyWidget parameter to constructor to avoid polluting signatures.
    /**
     * Get all card fields.
     * @param onlyActive Whether return only active (roughly speaking visible) fields.
     * @param isLegacyWidget Return fields of legacy widget card.
     * @param forCommission Filter fields that participate in commission operation.
     */
    public getFields(onlyActive = true, forCommission = true, isLegacyWidget = false): JQuery[] {
        return this.filterFields(forCommission, isLegacyWidget)
            .map((field) => this.getField(field.name, onlyActive));
    }

    public getField(
        field: AnonymousCardFieldName,
        onlyActive: boolean = true
    ): JQuery {
        const res = $(
            "[name='" + field + "']" + (onlyActive ? ":visible" : ""),
            this._context
        );

        if (res.length == 0 && field == "Pan")
            return $(
                `${smallCardContextSelector}:visible .vncHiddenPan`,
                this._context
            );

        return res;
    }

    public checkField(
        fieldInfo: AnonymousCardField,
        onlyActive?: boolean,
        ignoreRules?: string[]
    ): boolean {
        const fieldQuery = this.getField(fieldInfo.name, onlyActive);
        let isFieldValid = true;

        if (fieldInfo.commissionDetails?.required && !fieldQuery.length) {
            return false;
        }

        fieldQuery.each((_, singleField) => {
            if (!$.checkWithoutCachedError($(singleField), ignoreRules)) {
                return (isFieldValid = false);
            }
        });

        return isFieldValid;
    }

    public checkFields(
        onlyActive?: boolean,
        ignoreRules?: string[],
        forCommission = true,
        isLegacyWidget = false
    ) {
        return this.filterFields(forCommission, isLegacyWidget)
            .every((field) => this.checkField(field, onlyActive, ignoreRules));
    }

    public getActiveExpiryValue(): IExpiry {
        let expiryMonthYearElement = this.getField("ExpiryMonthYear");

        if (expiryMonthYearElement.length)
            return Expiry.parse(expiryMonthYearElement.val() as string);

        let expiryMonth = this.getField("ExpiryMonth").val() as number;
        let expiryYear = this.getField("ExpiryYear").val() as number;

        return new Expiry(expiryMonth, expiryYear);
    }

    public disableValidation() {
        this.toggleValidation(false);
    }

    public enableValidation() {
        this.toggleValidation(true);
    }

    private filterFields(forCommission = true, isLegacyWidget = false): AnonymousCardField[] {
        return AnonymousCard.fieldsInfo
            .filter((field) => {
                if (forCommission && !field.commissionDetails) {
                    return false;
                }
                return field.legacy !== !isLegacyWidget;
            })
    }

    private toggleValidation(enable: boolean) {
        this.getFields(undefined, false)
            .forEach((field) => field.toggleClass("ignore", !enable));
    }
}

declare global {
    // TODO: Add support for all card fields (e.g. include Cvc)
    type AnonymousCardFieldName = typeof AnonymousCard.fieldNames[number];
    type AnonymousCardFieldsContainer = Record<AnonymousCardFieldName, JQuery>;

    interface AnonymousCardField {
        /** Name of card field. */
        name: AnonymousCardFieldName
        /**
         * Whether field is related to legacy widget form.
         * If true - is related only to legacy widget card, if false - only to new widget, if undefined - to both.
         * */
        legacy?: boolean
        /** Information about commission participation of field. */
        commissionDetails?: FieldCommissionInfo
    }

    interface FieldCommissionInfo {
        /** Whether field is required for commission calculation. */
        required: boolean
    }
}
