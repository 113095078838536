export class ProcessingErrorMapper {
    private static readonly errorDataName: string = "error";
    private static readonly ruleDataName: string = "msg-terms-available";
    private static readonly defaultErrorSelector = ".vnc-error";
    private static readonly defaultErrorMessageSelector = ".vnc-error-message";

    private readonly _defaultElement: JQuery;
    private readonly _elementErrorCodeDictionary: Partial<Record<ProcessingCenterResultCode, JQuery>>;

    public constructor(
        elementErrorCodeDictionary: Partial<Record<ProcessingCenterResultCode, JQuery>>,
        defaultElement: JQuery = null
    ) {
        this._elementErrorCodeDictionary = elementErrorCodeDictionary;
        this._defaultElement = defaultElement;
    }

    public getErrorElements(): JQuery {
        let allElements: JQuery;

        const keys = (Object.keys(this._elementErrorCodeDictionary) as unknown) as ProcessingCenterResultCode[];
        for (var i = 0; i < keys.length; i++) {
            const curElement = this._elementErrorCodeDictionary[keys[i]];

            allElements = allElements ? allElements.add(curElement) : curElement;
        }

        if (this._defaultElement?.data(ProcessingErrorMapper.errorDataName)) {
            allElements = allElements.add(this._defaultElement)
        }

        return allElements;
    }

    public getElementByErrorCode(errorCode: ProcessingCenterResultCode): JQuery {
        return errorCode in this._elementErrorCodeDictionary
            ? this._elementErrorCodeDictionary[errorCode]
            : this._defaultElement;
    }

    public setError(
        errorCode: ProcessingCenterResultCode,
        message: string
    ): void {
        const target = this.getElementByErrorCode(errorCode);
        const error: ITermError = {
            code: errorCode,
            msg: message,
        };

        if (target) {
            ProcessingErrorMapper.setElementError(target, error);
        }

        if (target === this._defaultElement) {
            this.setDefaultError(error);
        }
    }

    public clearErrors(): void {
        const allElements = this.getErrorElements();
        ProcessingErrorMapper.clearElementError(allElements);
        this.clearDefaultError();
    }

    public static setElementError(element: JQuery, error: ITermError): void {
        element.data(ProcessingErrorMapper.errorDataName, error);
        element.data(ProcessingErrorMapper.ruleDataName, error?.msg);

        if (element.length) element.valid();
    }

    public static clearElementError(elementQuery: JQuery): void {
        elementQuery.each(function (_, element) {
            const query = $(element);
            const hasError = !!query.data(ProcessingErrorMapper.errorDataName);
            query.removeData(ProcessingErrorMapper.errorDataName);
            query.data(ProcessingErrorMapper.ruleDataName, "");

            if (hasError) query.valid();
        });
    }

    private setDefaultError(error: ITermError): void {
        const defaultErrorWrapper = $(ProcessingErrorMapper.defaultErrorSelector);
        const defaultErrorMessage = $(ProcessingErrorMapper.defaultErrorMessageSelector, defaultErrorWrapper);

        defaultErrorMessage.html(error.msg);
        defaultErrorWrapper.fadeIn();
    }

    private clearDefaultError(): void {
        const defaultErrorWrapper = $(ProcessingErrorMapper.defaultErrorSelector);
        const defaultErrorMessage = $(ProcessingErrorMapper.defaultErrorMessageSelector, defaultErrorWrapper);

        $(ProcessingErrorMapper.defaultErrorSelector).fadeOut(
            undefined,
            () => { defaultErrorMessage.empty(); }
        );
    }
}

declare global {
    interface ITermError {
        code: ProcessingCenterResultCode;
        msg: string;
    }
}
