import { createTuple, smallCardContextSelector } from "./anonymous-card";
import { AutotabChain } from "./autotab-chain";
import { Key } from './enums';

export class SmallAnonymousCard {
    public static readonly contextSelector = smallCardContextSelector;
    public static readonly subscriptionEvents = "field_invalid field_valid";
    public static readonly fieldNames = createTuple("Pan", "ExpiryMonthYear");

    private _cardAutotabItems: CommonMisc.Dictionary<
        AnonymousCardFieldName | "Cvc",
        AutotabChainItem
    >;
    private _autotabChain: AutotabChain;
    private _changeHandler: (eventObject: JQueryEventObject) => any;
    private readonly pan: string;
    private readonly cvc: string;
    private readonly expiry: string;
    private readonly panWrapper: string;
    private readonly nextBtn: string;
    private readonly clearPanBtn: string;
    private readonly maskedPan: string;
    private readonly _context: JQuery;

    private wrap(elementSelector: string) {
        return `${SmallAnonymousCard.contextSelector}:visible ${elementSelector}:not(.vncSelectItem *)`;
    }
    //проверка на то что если PAN при открытии страницы уже предзаполнен
    //убираем пробулы и если он годный маскируем его показывая остальные поля
    private preformatFieldsValue() {
        if ($(this.pan).length && $(this.pan).val().length) {
            $(this.pan).val($(this.pan).val().replace(/\D/g, ''))
            var self = this;
            setTimeout(function () {
                $(self.pan).trigger(jQuery.Event('keydown', { keyCode: Key.RIGHT_ARROW }));
            }, 300)
        }
    }
    constructor(
        context: JQuery | Factory<JQuery>,
        focusIn?: () => void,
        changeHandler?: (eventObject: JQueryEventObject) => any
    ) {
        this._context = this.getContext(context);

        const panSelector = "[name=Pan]";
        this.pan = this.wrap(panSelector);
        this.cvc = this.wrap("[name=Cvc]");
        this.expiry = this.wrap("[name=ExpiryMonthYear]");
        this.preformatFieldsValue();
        this.panWrapper = this.wrap(
            `.b-add-card-input__input_card:has(${panSelector})`
        );
        this.maskedPan = this.wrap(".vncMaskedPan");
        this.clearPanBtn = this.wrap(".vncClearPanBtn");
        this.nextBtn = this.wrap(".vncNextBtn");

        if (changeHandler) this.subscribe(changeHandler);

        this._cardAutotabItems = new CommonMisc.Dictionary(
            this.constructAutotabChain()
        );
        this._autotabChain = SmallAnonymousCard.createAutotabChain(
            this._cardAutotabItems.getValues()
        );

        this._context.on(
            "card-detected",
            this.pan,
            (_, type) => !this.isCvcOnly() && this.setCardIcon(type)
        );
        this._context.on(
            "click",
            this.clearPanBtn,
            () => !this.isCvcOnly() && this.clearPan(true)
        );
        this._context.on(
            "focus",
            this.maskedPan,
            () => !this.isCvcOnly() && this.unmaskPan()
        );
        this._context.on(
            "focus",
            this.pan,
            () => !this.isCvcOnly() && focusIn && focusIn()
        );
        this._context.on(
            "blur",
            this.pan,
            () => !this.isCvcOnly() && this.panBlur()
        );
        this._context.on("input", this.pan, () => this.panInput());
    }

    private getContext(contextFactory: JQuery | Factory<JQuery>): JQuery {
        const context = CommonMisc.resolve(contextFactory);
        if (!context?.length) {
            console.error("Unable to get context", contextFactory, console.trace());
        }

        return context;
    }

    public get autotabChain(): AutotabChain {
        return this._autotabChain;
    }

    public getCvc(): JQuery {
        return this.getQuery(this.cvc);
    }

    private subscribe(handler: (eventObject: JQueryEventObject) => any) {
        SmallAnonymousCard.subscribeField(
            this._context,
            this.pan,
            handler,
            this._changeHandler
        );
        SmallAnonymousCard.subscribeField(
            this._context,
            this.expiry,
            handler,
            this._changeHandler
        );
        this._changeHandler = handler;
    }

    public getActiveExpiry(): JQuery {
        return $(this.expiry, this._context);
    }

    private getQuery(elementSelector: string) {
        return $(elementSelector, this._context);
    }

    private getComplexPan(): JQuery {
        return this.getQuery(`${this.maskedPan}, ${this.pan}`);
    }

    private isCvcOnly() {
        return $(this.wrap("#CvcOnly"), this._context).val() === "True";
    }

    private isPanOnly() {
        return $(this.wrap("#PanOnly"), this._context).val() === "True";
    }

    private constructAutotabChain(): {
        [name in AnonymousCardFieldName | "Cvc"]?: AutotabChainItem;
    } {
        const context = this._context;
        const expirySelector = this.expiry;
        const cvcSelector = this.cvc;
        return {
            Pan: {
                src: context,
                selector: `${this.maskedPan}, ${this.pan}`,
                predicate: (query) => {
                    const moveNext = CommonMisc.PanAutotabPredicate(query);
                    if (moveNext) query.blur();
                    return moveNext;
                },
                prevAction: () => this.unmaskPan(),
                onBeforeNextFocus: () => this.unmaskPan(),
                moveNextCondition: CommonMisc.PanAutotabPredicate,
                moveOnKeyPress: SmallAnonymousCard.canMoveOnKeypress,
                maxLen: 23,
            },
            ExpiryMonthYear: {
                src: context,
                selector: expirySelector,
                predicate: (src: JQuery) => src.val()?.length >= 4 && src.valid(),
                prevAction: () => this.setFocusOnExpiry(),
                maxLen: 5,
            },
            Cvc: {
                src: context,
                selector: cvcSelector,
                predicate: (src: JQuery) => src.val()?.length >= 3 && src.valid(),
                moveOnKeyPress: true,
                maxLen: 3,
            },
        };
    }

    public focus(): void {
        this.getComplexPan().filter(":visible").first().focus();
    }

    public bindAutotabChain(
        externalChain: AutotabChainItem[],
        toEnd: boolean = false
    ): this {
        (toEnd ? externalChain : externalChain.reverse()).forEach((itemToAdd) => {
            if (toEnd) {
                this._autotabChain.append(itemToAdd);
            } else {
                this._autotabChain.prepend(itemToAdd);
            }
        });

        return this;
    }

    public Clear() {
        this.clear();
    }

    public Disable() {
        this.getQuery(this.pan).toggleClass("ignore", true);
        this.getQuery(this.cvc).toggleClass("ignore", true);
        this.getQuery(this.expiry).toggleClass("ignore", true);
    }

    public Enable() {
        this.getQuery(this.pan).toggleClass("ignore", false);
        this.getQuery(this.cvc).toggleClass("ignore", false);
        this.getQuery(this.expiry).toggleClass("ignore", false);
    }

    public initMask() {
        this.getQuery(this.wrap("[data-inputmask]")).inputmask();
    }

    private clear() {
        this.getQuery(this.expiry).val("");
        this.getQuery(this.cvc).val("");
        this.clearPan(false);
        this.getQuery(this.nextBtn).hide();
    }

    /** @deprecated Use AutotabChain instead. */
    public static createAutotabChain(chain: AutotabChainItem[]): AutotabChain {
        return new AutotabChain(chain);
    }

    public static createInitializedSmallCard(
        context: JQuery | Factory<JQuery>,
        handler: (eventObject: JQueryEventObject) => any,
        focusIn?: () => void
    ) {
        return this.create(context, CommonMisc.withDelay(handler), focusIn);
    }

    /**
     * Reinitialize small card (update subscribtions) and return new instance.
     * @param instance Existing instance of small card to reinitialize.
     * @returns New instance of small card.
     */
    public static reinitialize(
        instance: SmallAnonymousCard,
        focusIn?: () => void
    ): SmallAnonymousCard {
        if (!instance) throw new Error("Card instance is not defined.");

        instance.focus()

        return instance;
    }

    /**
     * Predicate that indicated whether it's allowed to move from pan automatically.
     * @param query Query of element to move from.
     */
    public static canMoveOnKeypress(query: JQuery): boolean {
        const allowedLengthList: number[] = [16, 18, 19];

        return allowedLengthList.indexOf(query.val().length) !== -1;
    }

    private clearPan(setFocus: boolean) {
        const panQuery = this.getQuery(this.pan);
        panQuery.val("");
        this.getQuery(this.panWrapper).removeClass("is-next is-filled is-clear");
        this.setCardIcon(this.getCardType());

        if (!!window.Validation2) window.Validation2.Unhighlight(panQuery);

        this.getQuery(this.maskedPan).text("");

        if (setFocus) setTimeout(() => panQuery.first().focus());
    }

    private panInput() {
        const panQuery = this.getQuery(this.pan);
        const panWrapperQuery = this.getQuery(this.panWrapper);
        const val = panQuery.val();
        const panIsValid = CommonMisc.PanAutotabPredicate(panQuery);

        panWrapperQuery.toggleClass("is-clear", val && !panIsValid);
        panWrapperQuery.toggleClass("is-next", panIsValid);
        this.getQuery(this.nextBtn).toggle(panIsValid);
    }

    private panBlur() {
        const panWrapperQuery = this.getQuery(this.panWrapper);
        const val = this.getQuery(this.pan).val();

        if ($.payment.validateCardNumber(val) && !this.isPanOnly()) {
            this.maskPan();
        } else if (val) {
            panWrapperQuery.addClass("is-clear");
        }
    }

    private maskPan() {
        const panWrapperQuery = this.getQuery(this.panWrapper);
        const val = this.getQuery(this.pan).val();
        var lastNum = val.slice(-4);
        this.getQuery(this.maskedPan).text("*" + lastNum);
        panWrapperQuery.removeClass("is-clear is-next");
        panWrapperQuery.addClass("is-filled");
        this.getQuery(this.nextBtn).hide();
        this.setCardIcon(this.getCardType());
        this.getQuery(this.expiry).focus();
    }

    private unmaskPan() {
        const panQuery = this.getQuery(this.pan);
        const panWrapperQuery = this.getQuery(this.panWrapper);
        const btnNextVisible = CommonMisc.PanAutotabPredicate(panQuery);
        panWrapperQuery.toggleClass("is-next", btnNextVisible);
        panWrapperQuery.removeClass("is-filled");
        this.getQuery(this.nextBtn).toggle(btnNextVisible);

        panQuery.first().animate({}, "slow", function () {
            const pan = $(this);
            pan.focus();
            CommonMisc.setCursorToLastSymbol(pan);
        });

    }

    private setCardIcon(type: string) {
        const feature_SITE_736 = new CommonMisc.FeatureToggle().isEnabled('SITE_736');
        if (feature_SITE_736) {
            this.getCardPayType().then(paytype => {
                this.getQuery(this.wrap(".vncPayIcon")).hide();
                this.getQuery(this.wrap(`.vncPayIcon_${paytype?.toLowerCase() || "default"}`)).show();

                if (!this.getQuery(this.wrap(".vncPayIcon:visible")).length) {
                    this.getQuery(this.wrap(".vncPayIcon_default")).show();
                }
            });
        } else {
            this.getQuery(this.wrap(".vncPayIcon")).hide();
            this.getQuery(this.wrap(`.vncPayIcon_${type || "default"}`)).show();

            if (!this.getQuery(this.wrap(".vncPayIcon:visible")).length) {
                this.getQuery(this.wrap(".vncPayIcon_default")).show();
            }

        }
        

    }

    private getCardType() {
        return $.payment.cardType(this.getQuery(this.pan).val());
    }

    private async getCardPayType() {
        let pan = this.getQuery(this.pan).val();
        let lastPan = this.getQuery(this.pan).data('lastPan');
        if (lastPan && lastPan.replace(/\D/g, '') === pan.replace(/\D/g, '')) {
            return this.getQuery(this.pan).data("card-type")
        }
        let cardtype = await $.getCardPay(pan.replace(/\D/g, ''));
        this.getQuery(this.pan).data('lastPan', pan);
        return cardtype;
    }

    private setFocusOnExpiry() {
        CommonMisc.filterFocusable(this.getQuery(this.expiry))
            .first()
            .animate({}, "slow", function () {
                const expiry = $(this);
                expiry.focus();
                CommonMisc.setCursorToLastSymbol(expiry);
            });
    }

    private static create(
        context: JQuery | Factory<JQuery>,
        handler: (eventObject: JQueryEventObject) => any,
        focusIn?: () => void
    ) {
        const smallAnonymousCard = new SmallAnonymousCard(
            context,
            focusIn,
            handler
        );
        smallAnonymousCard.subscribe(handler);

        return smallAnonymousCard;
    }

    private static unsubscribeField(
        context: JQuery,
        selector: string,
        oldHandler?: (eventObject: JQueryEventObject) => any
    ): JQuery {
        if (!selector) {
            console.error("Argument is empty.", selector);
            return;
        }

        return context.off(
            SmallAnonymousCard.subscriptionEvents,
            selector,
            oldHandler
        );
    }

    private static subscribeField(
        context: JQuery,
        selector: string,
        handler: (eventObject: JQueryEventObject) => any,
        oldHandler?: (eventObject: JQueryEventObject) => any
    ): JQuery {
        return SmallAnonymousCard.unsubscribeField(
            context,
            selector,
            oldHandler
        ).on(SmallAnonymousCard.subscriptionEvents, selector, handler);
    }
}
